var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register"},[_c('h3',[_vm._v("Login")]),_c('p',[_vm._v("silahkan isi lalu login,")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nama',
          {
            rules: [
              {
                required: true,
                message: 'Silahkan isi field ini',
              },
            ],
          },
        ]),expression:"[\n          'nama',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'Silahkan isi field ini',\n              },\n            ],\n          },\n        ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Nama"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                required: true,
                message: 'Silahkan isi field ini',
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                required: true,\n                message: 'Silahkan isi field ini',\n              },\n            ],\n          },\n        ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Email"}})],1),_c('a-button',{attrs:{"type":"primary","html-type":"handleSubmit","loading":_vm.isLoading,"block":""}},[_vm._v(" Masuk "),_c('a-icon',{attrs:{"type":"play-circle"}})],1)],1),_c('div',{staticClass:"imagesregist"},[_c('img',{attrs:{"src":require('@/assets/img/register.png')}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }