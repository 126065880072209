<template>
  <div class="register">
    <h3>Login</h3>
    <p>silahkan isi lalu login,</p>

    <a-form :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          autocomplete="off"
          v-decorator="[
            'nama',
            {
              rules: [
                {
                  required: true,
                  message: 'Silahkan isi field ini',
                },
              ],
            },
          ]"
          type="text"
          placeholder="Nama"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          autocomplete="off"
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  required: true,
                  message: 'Silahkan isi field ini',
                },
              ],
            },
          ]"
          type="text"
          placeholder="Email"
        />
      </a-form-item>

      <a-button
        type="primary"
        html-type="handleSubmit"
        :loading="isLoading"
        block
      >
        Masuk <a-icon type="play-circle" />
      </a-button>
    </a-form>

    <div class="imagesregist">
      <img :src="require('@/assets/img/register.png')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "FormLogin" });
  },
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    ...mapState(["token"]),
    ...mapGetters(["isAuth"]),
  },
  created() {
    if (this.isAuth == true) {
      this.$router.push({ name: "HomeWelcome" });
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store
            .dispatch("retrieveToken", {
              nama: values.nama,
              email: values.email,
            })
            .then((response) => {
              if (this.isAuth) {
                this.$router.push({ name: "HomeWelcome" });
              }
            });
        }
      });
    },
  },
};
</script>
